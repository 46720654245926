import { all, call, put, takeLeading } from 'redux-saga/effects'

import api from '../../../services/api'

import { actions, actionTypes } from './actions'
import { AdvisersAction } from './types'

export function* getAdvisersPageData() {
  try {
    const response = yield call(api.getAdvisersPageData)

    yield put(actions.pageDataSuccess(response.data))
  } catch (error) {
    yield put(actions.pageDataFailure(error))
  }
}

export default function* advisersRoot() {
  yield all([
    takeLeading<AdvisersAction>(actionTypes.PAGE_DATA.GET, getAdvisersPageData),
  ])
}
