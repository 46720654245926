import axios from 'axios'

import { EnvConfig } from '../../../constants/environmentConfig'

/**
 * A function to set the API context for the application of whether it is in
 * "Preview" mode or not.
 */
export const setAPIContext = (url: string) => {
  const inPreviewMode = url.indexOf('preview') !== -1

  const SERVER_URL = inPreviewMode
    ? EnvConfig.SPW_PREVIEW_UI_API_URL
    : EnvConfig.SPW_UI_API_URL

  axios.defaults.baseURL = SERVER_URL
}
