import { all, call, put, takeLeading } from 'redux-saga/effects'

import api from '../../../services/api'

import { actions, actionTypes } from './actions'
import { WorkWithUsAction } from './types'

export function* getWorkWithUsData() {
  try {
    const workWithUsDataResponse = yield call(api.getWorkWithUsPageData)

    yield put(actions.pageDataSuccess(workWithUsDataResponse.data))
  } catch (error) {
    yield put(actions.pageDataFailure(error))
  }
}

export default function* workWithUsRoot() {
  yield all([
    takeLeading<WorkWithUsAction>(actionTypes.PAGE_DATA.GET, getWorkWithUsData),
  ])
}
