import { ArticleCardData } from '../../../components/common/atoms/ArticleCard/types'
import { fetchGraphQL } from '../api'

export const ARTICLE_FRAGMENT = `{
  contentThemeTag
  contentFormatTag
  cardTitle
  cardDescription
  slug
  pageTheme
  metaData {
    timeOnPage
  }
  sys {
    publishedAt
  }
  articleHeroBlockCollection(limit: 1) {
    items {
      backgroundImageCollection(limit: 1) {
        items {
          altText
          entryTitle
          desktopImageCollection {
            items {
              url
              fileName
              contentType
              size
              width
              height
            }
          }
          tabletImageCollection {
            items {
              url
              fileName
              contentType
              size
              width
              height
            }
          }
          mobileImageCollection {
            items {
              url
              fileName
              contentType
              size
              width
              height
            }
          }
        }
      }
    }
  }
}`

type Args = {
  currentArticleSlug?: string
  contentThemeTag?: string
}

type Response = {
  data: {
    articleCollection: {
      items: ArticleCardData[]
    }
  }
  errors?: {
    message: string
  }
}

export async function getArticlesCards(
  limit: number,
  skip: number,
  preview = false,
  args: Args = {}
): Promise<Response> {
  let where = ''
  if (args.currentArticleSlug)
    where = `where: {slug_not: "${args.currentArticleSlug}"}`
  if (args.contentThemeTag)
    where = `where: {contentThemeTag_contains_all: "${args.contentThemeTag}"}`
  const entries = await fetchGraphQL(
    `query {
        articleCollection(
            preview: ${preview} 
            limit: ${limit} 
            skip: ${skip} 
            order: [sys_publishedAt_DESC]
            ${where}
          ) {
          items ${ARTICLE_FRAGMENT}
      }
    }`,
    preview
  )
  return entries
}
