import { rem } from 'polished'

const fontFamily = {
  primary: 'SchrodersCircular, sans-serif',
}

const baseFontSizePercentage = '62.5'

const fontWeights = {
  light: 300,
  normal: 400,
  bold: 700,
}

/**
 * A function that returns the REM value for the provided pixel font size.
 *
 * @param pixelValue - Required. The pixel value to be converted to REMs.
 *
 * @returns The REM size value.
 */
const convertToREM = (pixelValue: number): string => {
  const baseFontSizePixels = 16

  // Calculates the base font size as a pixel value.
  const baseFontSize =
    (Number(baseFontSizePercentage) / 100) * baseFontSizePixels

  return rem(pixelValue, baseFontSize)
}

const fontSizes = {
  desktop: {
    headings: {
      h1: convertToREM(50),
      h2: convertToREM(40),
      h3: convertToREM(35),
      h4: convertToREM(30),
      h5: convertToREM(25),
      h6: convertToREM(14),
    },
    body: {
      primary: convertToREM(18),
      secondary: convertToREM(23),
      tertiary: convertToREM(16),
    },
    overline: {
      primary: convertToREM(15),
    },
    button: {
      primary: convertToREM(15),
    },
    navigation: {
      primary: convertToREM(15),
      secondary: convertToREM(15),
    },
    table: convertToREM(14),
    anchorNavLink: {
      primary: convertToREM(14),
    },
    tag: {
      primary: convertToREM(11),
    },
    footer: {
      primary: convertToREM(14),
    },
    input: {
      label: convertToREM(15),
      labelOnFocus: convertToREM(11),
      error: convertToREM(15),
    },
  },
  tablet: {
    headings: {
      h1: convertToREM(40),
      h2: convertToREM(35),
      h3: convertToREM(30),
      h4: convertToREM(25),
      h5: convertToREM(20),
      h6: convertToREM(12),
    },
    body: {
      primary: convertToREM(16),
      secondary: convertToREM(18),
      tertiary: convertToREM(15),
    },
    overline: {
      primary: convertToREM(15),
    },
    button: {
      primary: convertToREM(15),
    },
    navigation: {
      primary: convertToREM(14),
      secondary: convertToREM(15),
    },
    table: convertToREM(12),
    anchorNavLink: {
      primary: convertToREM(14),
    },
    tag: {
      primary: convertToREM(11),
    },
    footer: {
      primary: convertToREM(14),
    },
    input: {
      label: convertToREM(15),
      error: convertToREM(15),
    },
  },
  mobile: {
    headings: {
      h1: convertToREM(35),
      h2: convertToREM(30),
      h3: convertToREM(25),
      h4: convertToREM(20),
      h5: convertToREM(16),
      h6: convertToREM(11),
    },
    body: {
      primary: convertToREM(16),
      secondary: convertToREM(18),
      tertiary: convertToREM(14),
    },
    overline: {
      primary: convertToREM(12),
    },
    button: {
      primary: convertToREM(14),
    },
    navigation: {
      primary: convertToREM(14),
      secondary: convertToREM(15),
    },
    table: convertToREM(11),
    anchorNavLink: {
      primary: convertToREM(15),
    },
    tag: {
      primary: convertToREM(11),
    },
    footer: {
      primary: convertToREM(14),
    },
    input: {
      label: convertToREM(15),
      error: convertToREM(14),
    },
  },
}

const lineHeights = {
  none: 0,
  base: 1,
  close: 1.1,
  tight: 1.27,
  compact: 1.43,
  normal: 1.5,
  default: 1.56,
  medium: 1.63,
  large: 1.78,
}

const letterSpacings = {
  overline: convertToREM(4),
}

export default {
  baseFontSizePercentage,
  letterSpacings,
  fontFamily,
  fontWeights,
  fontSizes,
  lineHeights,
}
