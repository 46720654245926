import { all, call, put, takeLeading } from 'redux-saga/effects'

import api from '../../../services/api'

import { actions, actionTypes } from './actions'
import { ContactAction } from './types'

export function* getContactData() {
  try {
    const contactDataResponse = yield call(api.getContactPageData)

    yield put(actions.pageDataSuccess(contactDataResponse.data))
  } catch (error) {
    yield put(actions.pageDataFailure(error))
  }
}

export default function* contactRoot() {
  yield all([
    takeLeading<ContactAction>(actionTypes.PAGE_DATA.GET, getContactData),
  ])
}
