import { actionTypes } from './actions'

export const initialState = {
  visible: false,
  loading: false,
  formData: undefined,
  error: undefined,
  errorMessage: undefined,
  success: undefined,
  submitResponse: undefined,
  timeslots: undefined,
  candidates: undefined,
}

export default (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.OPEN:
      return {
        ...state,
        visible: true,
      }

    case actionTypes.CLOSE:
      return {
        ...initialState,
        formData: state.formData,
        timeslots: state.timeslots,
        candidates: state.candidates,
      }

    case actionTypes.LOAD:
      return {
        ...state,
        loading: true,
      }

    case actionTypes.LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        formData: action.payload.formData,
      }

    case actionTypes.LOAD_TIMESLOTS_SUCCESS:
      return {
        ...state,
        loading: false,
        timeslots: action.payload.timeSlots,
      }

    case actionTypes.LOAD_CANDIDATES_SUCCESS:
      return {
        ...state,
        loading: false,
        candidates: action.payload.candidates,
      }

    case actionTypes.LOAD_FAILURE:
      return {
        ...state,
        error: true,
        loading: false,
        visible: false,
      }

    case actionTypes.SAVE:
      return {
        ...state,
        formInput: action.payload.formInput,
        loading: true,
      }

    case actionTypes.SAVE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        error: false,
      }

    case actionTypes.SAVE_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        errorMessage: action.payload.errorMessage,
        loading: false,
        success: false,
      }

    case actionTypes.BACK: {
      // Keeps the formData previously loaded.
      return {
        ...state,
        error: false,
        errorMessage: undefined,
        loading: false,
        success: false,
        visible: true,
      }
    }

    default:
      return state
  }
}
