import { all, call, put, takeLeading } from 'redux-saga/effects'

import api from '../../../../services/api'
import { getArticlesCards } from '../../../../services/contentful/articles'

import { actions, actionTypes } from './actions'
import { GlobalAction } from './types'

export function* getGlobalData() {
  try {
    const getGlobalDataResponse = yield call(api.getGlobalData)

    if (getGlobalDataResponse) {
      yield put(actions.globalDataSuccess(getGlobalDataResponse.data.items[0]))
    }
  } catch (error) {
    yield put(actions.globalDataFailure(error))
  }
}

export function* getSelectedArticles(action) {
  try {
    const selectedArticleResponse = yield call(
      getArticlesCards,
      action.payload.selectedArticles.limit,
      action.payload.selectedArticles.offset,
      action.payload.preview,
      { currentArticleSlug: action.payload.selectedArticles.currentArticleSlug }
    )

    yield put(actions.selectedArticlesSuccess(selectedArticleResponse.data))
  } catch (error) {
    yield put(actions.selectedArticlesFailure(error))
  }
}

export default function* root() {
  yield all([
    takeLeading<GlobalAction>(actionTypes.GLOBAL_DATA.GET, getGlobalData),
    takeLeading<GlobalAction>(
      actionTypes.SELECTED_ARTICLES.GET,
      getSelectedArticles
    ),
  ])
}
