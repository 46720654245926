import { all, call, put, takeLeading } from 'redux-saga/effects'

import api from '../../../services/api'

import { actions, actionTypes } from './actions'
import { WhatWeDoAction } from './types'

export function* getWhatWeDoData() {
  try {
    const whatWeDoDataResponse = yield call(api.getWhatWeDoPageData)

    yield put(actions.pageDataSuccess(whatWeDoDataResponse.data))
  } catch (error) {
    yield put(actions.pageDataFailure(error))
  }
}

export default function* whatWeDoRoot() {
  yield all([
    takeLeading<WhatWeDoAction>(actionTypes.PAGE_DATA.GET, getWhatWeDoData),
  ])
}
