import { combineReducers } from 'redux'

import callbackFormReducer from '../components/common/organisms/CallbackFormModal/reducer'
import globalReducer from '../components/common/templates/Layout/reducer'
import aboutReducer from '../components/containers/About/reducer'
import adviserProfileReducer from '../components/containers/AdviserProfile/reducer'
import advisersReducer from '../components/containers/Advisers/reducer'
import articleReducer from '../components/containers/Article/reducer'
import campaignReducer from '../components/containers/Campaign/reducer'
import customSlugReducer from '../components/containers/CustomSlug/reducer'
import contactReducer from '../components/containers/Contact/reducer'
import faqReducer from '../components/containers/Faq/reducer'
import fundInfoReducer from '../components/containers/FundInfo/reducer'
import homeReducer from '../components/containers/Home/reducer'
import howItWorksReducer from '../components/containers/HowItWorks/reducer'
import mediaCentreReducer from '../components/containers/MediaCentre/reducer'
import preferencesCentreReducer from '../components/containers/PreferenceCentre/reducer'
import pricingReducer from '../components/containers/Pricing/reducer'
import profilingToolReducer from '../components/containers/ProfilingTool/reducer'
import profilingToolResultsReducer from '../components/containers/ProfilingToolResults/reducer'
import referralReducer from '../components/containers/Referral/reducer'
import referReducer from '../components/containers/Refer/reducer'
import whatWeDoReducer from '../components/containers/WhatWeDo/reducer'
import workWithUsReducer from '../components/containers/WorkWithUs/reducer'

const rootReducer = combineReducers({
  about: aboutReducer,
  adviserProfile: adviserProfileReducer,
  advisers: advisersReducer,
  article: articleReducer,
  callbackForm: callbackFormReducer,
  campaign: campaignReducer,
  customSlug: customSlugReducer,
  referral: referralReducer,
  refer: referReducer,
  contact: contactReducer,
  faq: faqReducer,
  fundInfo: fundInfoReducer,
  global: globalReducer,
  home: homeReducer,
  howItWorks: howItWorksReducer,
  mediaCentre: mediaCentreReducer,
  preferenceCentre: preferencesCentreReducer,
  pricing: pricingReducer,
  profilingTool: profilingToolReducer,
  profilingToolResults: profilingToolResultsReducer,
  whatWeDo: whatWeDoReducer,
  workWithUs: workWithUsReducer,
})

export default rootReducer
