import { all, call, put, takeLeading } from 'redux-saga/effects'

import api from '../../../services/api'

import { actions, actionTypes } from './actions'
import { HowItWorksAction } from './types'

export function* getHowItWorksData() {
  try {
    const howItWorksDataResponse = yield call(api.getHowItWorksPageData)

    yield put(actions.pageDataSuccess(howItWorksDataResponse.data))
  } catch (error) {
    yield put(actions.pageDataFailure(error))
  }
}

export default function* howItWorksRoot() {
  yield all([
    takeLeading<HowItWorksAction>(actionTypes.PAGE_DATA.GET, getHowItWorksData),
  ])
}
