import { FeeRate } from '../../components/common/molecules/PricingDetailBlock/types'
import { ANCHOR_NAV_TOP_OFFSET_BREAKPOINT } from '../../constants'
import { SeoMetaData } from '../../types/metaData'

// Reuse this to avoid redundant checks like value && value[0]
export const extractor = (value, index = 0) => {
  return (Array.isArray(value) && value.length >= index && value[index]) || {}
}

export const formQueryParam = (params) => {
  if (params) {
    const queryString = Object.keys(params).reduce(
      (acc, value) => (acc += `${value}=${params[value]}&`),
      ''
    )
    return queryString.substring(0, queryString.length - 1)
  }
  return ''
}

export const anchorNavScrollListener = (ref) => {
  let lastScrollTop = 0

  return () => {
    if (!ref.current) {
      return
    }

    const topOffset = ref.current.getBoundingClientRect().top

    if (topOffset <= ANCHOR_NAV_TOP_OFFSET_BREAKPOINT) {
      ref.current.classList.add('navbar-sticky')
    } else {
      ref.current.classList.remove('navbar-sticky')
    }

    const currentScrollTop =
      window.pageYOffset || document.documentElement.scrollTop

    if (currentScrollTop > lastScrollTop) {
      ref.current.classList.remove('has-offset')
    } else {
      ref.current.classList.add('has-offset')
    }

    lastScrollTop = currentScrollTop <= 0 ? 0 : currentScrollTop
  }
}

export const buttonKeyDownHandler = (event, callback) => {
  if (event.key === ' ' || event.key === 'Enter' || event.key === 'Spacebar') {
    event.preventDefault()
    callback()
  }
}

export const scrollToElement = (element: HTMLElement, offset: number) => {
  const y = element.getBoundingClientRect().top + window.pageYOffset + offset

  if (element) {
    window.scrollTo({
      top: y,
      behavior: 'smooth',
    })
  }
}

export const getFee = (
  _investmentValue: number,
  rateIndex: number,
  totalInvestmentValue: number,
  rates: FeeRate[],
  currentFeeRate?: FeeRate
) => {
  if (isNaN(_investmentValue)) {
    return 0
  }

  if (_investmentValue <= 0 || rateIndex >= rates.length) {
    return 0
  } else {
    const rate = rates[rateIndex]

    if (
      currentFeeRate?.flatRate > 0 &&
      _investmentValue <= currentFeeRate?.maximumAmount
    ) {
      // 1. Flat rate is applicable when invested amt is between min and max
      // 2. If flat rate is NOT provided it automatically fallsback to percentage
      // 3. If invested amt goes over the max the rate stays as is
      return currentFeeRate.flatRate
    } else {
      const max = rate.maximumAmount || totalInvestmentValue
      const min = rate.minimumAmount || 0
      const feePercentage = rate.feePercentage || 0

      const coeff = Math.min(_investmentValue / (max - min), 1) * (max - min)
      const value = (coeff * feePercentage) / 100

      return (
        value +
        getFee(
          _investmentValue - coeff,
          rateIndex + 1,
          totalInvestmentValue,
          rates
        )
      )
    }
  }
}

export const isUrlExternalTarget = (url: string): boolean =>
  url && (url.startsWith('www') || url.startsWith('http'))

export const overrideCanonicalTag = (
  seoMetaData: SeoMetaData,
  canonicalTag: string
): void => {
  if (!seoMetaData.canonicalTag || seoMetaData.canonicalTag.length === 0) {
    if (canonicalTag.startsWith('/')) {
      canonicalTag = canonicalTag.substr(1)
    }
    seoMetaData.canonicalTag = canonicalTag
  }
}

export const formatTelephoneSF = (value) => {
  value = value.replace(/[^\d+]+/g, '')
  value = value.replace(/^\+44/, '')
  value = value.replace(/^0/, '')

  return value
}

export const generateUUID = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

export const setUTM = () => {
  if (typeof window !== 'undefined') {
    const query = new URLSearchParams(window.location.search)
    const utmValues = [
      'utm_source',
      'utm_medium',
      'utm_campaign',
      'utm_content',
      'utm_term',
    ]

    utmValues.forEach((utmValue) => {
      const value = query.get(utmValue)
      if (value) {
        sessionStorage.setItem(utmValue, value)
      }
    })
  }
}

export const fetchUTM = () => {
  if (typeof window === 'undefined')
    throw Error('Server Side Call: This function is Client side only')
  return {
    utm_source: sessionStorage.getItem('utm_source'),
    utm_medium: sessionStorage.getItem('utm_medium'),
    utm_campaign: sessionStorage.getItem('utm_campaign'),
    utm_content: sessionStorage.getItem('utm_content'),
    utm_term: sessionStorage.getItem('utm_term'),
  }
}

export const getUTM = (formValues: any) => {
  const pi__utm_source__c = sessionStorage.getItem('utm_source')
  const pi__utm_medium__c = sessionStorage.getItem('utm_medium')
  const pi__utm_campaign__c = sessionStorage.getItem('utm_campaign')
  const pi__utm_content__c = sessionStorage.getItem('utm_content')
  const pi__utm_term__c = sessionStorage.getItem('utm_term')

  const newFormValues = { ...formValues }

  if (pi__utm_source__c) newFormValues.pi__utm_source__c = pi__utm_source__c
  if (pi__utm_medium__c) newFormValues.pi__utm_medium__c = pi__utm_medium__c
  if (pi__utm_campaign__c)
    newFormValues.pi__utm_campaign__c = pi__utm_campaign__c
  if (pi__utm_content__c) newFormValues.pi__utm_content__c = pi__utm_content__c
  if (pi__utm_term__c) newFormValues.pi__utm_term__c = pi__utm_term__c

  return {
    ...newFormValues,
  }
}
