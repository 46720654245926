import { all, call, put, takeLeading } from 'redux-saga/effects'

import api from '../../../services/api'

import { actions, actionTypes } from './actions'
import { HomeAction } from './types'

export function* getHomeData() {
  try {
    const homeDataResponse = yield call(api.getHomePageData)
    yield put(actions.pageDataSuccess(homeDataResponse.data))
  } catch (error) {
    yield put(actions.pageDataFailure(error))
  }
}

export default function* homeRoot() {
  yield all([takeLeading<HomeAction>(actionTypes.PAGE_DATA.GET, getHomeData)])
}
