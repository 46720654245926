import { actionTypes } from './actions'
import { PageData, PreferenceCentreState, PreferencesData } from './types'

export const initialState: PreferenceCentreState = {
  error: false,
  loading: false,
  pageData: {
    total: 0,
    skip: 0,
    limit: 10,
    items: [{}],
    preferencesData: {} as any as PreferencesData,
  } as any as PageData,
  save: {
    error: false,
    loading: false,
    saved: false,
  },
  unsubscribe: {
    error: false,
    loading: false,
    unsubscribed: false,
  },
}

export default (
  state: PreferenceCentreState = initialState,
  action: any
): PreferenceCentreState => {
  switch (action.type) {
    case actionTypes.LOAD:
      return {
        ...state,
        loading: true,
      }

    case actionTypes.LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        pageData: action.payload.pageData,
      }

    case actionTypes.LOAD_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      }
    case actionTypes.SAVE:
      return {
        ...state,
        save: {
          loading: true,
          error: false,
          saved: false,
        },
      }
    case actionTypes.SAVE_SUCCESS:
      return {
        ...state,
        save: {
          loading: false,
          error: false,
          saved: true,
        },
        pageData: {
          ...state.pageData,
          preferencesData: action.payload.preferencesData,
        },
      }

    case actionTypes.SAVE_FAILURE:
      return {
        ...state,
        save: {
          loading: false,
          error: action.payload.error,
          saved: false,
        },
      }
    case actionTypes.UNSUBSCRIBE:
      return {
        ...state,
        unsubscribe: {
          loading: true,
          error: false,
          unsubscribed: false,
        },
      }
    case actionTypes.UNSUBSCRIBE_SUCCESS:
      return {
        ...state,
        unsubscribe: {
          loading: false,
          error: false,
          unsubscribed: true,
        },
        pageData: {
          ...state.pageData,
          preferencesData: action.payload.preferencesData,
        },
      }
    case actionTypes.UNSUBSCRIBE_FAILURE:
      return {
        ...state,
        unsubscribe: {
          loading: false,
          error: action.payload.error,
          unsubscribed: false,
        },
      }
    case actionTypes.RESET:
      return {
        ...state,
        save: {
          ...state.save,
          saved: action.payload.saved,
        },
        unsubscribe: {
          ...state.unsubscribe,
          unsubscribed: action.payload.unsubscribed,
        },
      }
    default:
      return state
  }
}
