import ReactGA from 'react-ga'

import { EnvConfig } from '../../constants/environmentConfig'

import { LogEvent } from './types'

const GA_TRACKING_ID = EnvConfig.SPW_UI_GA_TRACKING_ID

/**
 * A function to initialize Google Analytics.
 */
export const initGA = () => {
  ReactGA.initialize(GA_TRACKING_ID)
}

/**
 * A function to log a page view.
 *
 * @param path - Required. The path to be logged
 */
export const logPageView = (path: string, title?: string) => {
  ReactGA.set({ page: path })
  ReactGA.pageview(path, [], title)
}

/**
 * A function to log an event.
 *
 * @param arguments - Configuration object
 * @param arguments.category - Required. A top level category for these events.
 * E.g. 'User', 'Navigation', 'App Editing', etc.
 * @param arguments.action - Required. A description of the behaviour. E.g.
 * 'Clicked Delete', 'Added a component', 'Deleted account', etc.
 * @param arguments.label - Optional. More precise labelling of the related
 * action. E.g. alongside the 'Added a component' action, we could add the name
 * of a component as the label. E.g. 'Survey', 'Heading', 'Button', etc.
 * @param arguments.value - Optional. A means of recording a numerical value
 * against an event. E.g. a rating, a score, etc.
 * @param arguments.nonInteraction - Optional. If an event is not triggered by a
 * user interaction, but instead by our code (e.g. on page load, it should be
 * flagged as a nonInteraction event to avoid skewing bounce rate data.
 * @param arguments.transport - Optional. This specifies the transport mechanism
 * with which hits will be sent. Valid values include 'beacon', 'xhr', or
 * 'image'.
 * @param event - Required. The callback function fired in relation to logged
 * event.
 */
export const logEvent = (
  { category, action, label, value, nonInteraction, transport }: LogEvent,
  event?: (ev: any) => void,
  ev?: React.SyntheticEvent
) => {
  if (event) {
    event(ev)
  }
  if (category && action) {
    ReactGA.event({
      category,
      action,
      label,
      value,
      nonInteraction,
      transport,
    })
  } else {
    // tslint:disable-next-line:no-console
    console.error('Please provide valid `category` & `action` values')
  }
}
