import { ColorTheme } from '../../../types/colorThemes'

import blue from './blue'
import green from './green'
import grey from './grey'
import purple from './purple'
import red from './red'
import yellow from './yellow'
import rocketboy from './rocketboy'

const colorThemes: {
  blue: ColorTheme
  yellow: ColorTheme
  purple: ColorTheme
  red: ColorTheme
  green: ColorTheme
  grey: ColorTheme
  rocketboy: ColorTheme
} = {
  blue,
  yellow,
  purple,
  red,
  green,
  grey,
  rocketboy,
}

export default colorThemes
