import { all, call, put, takeLeading } from 'redux-saga/effects'

import api from '../../../services/api'

import { actions, actionTypes } from './actions'
import { ReferralAction } from './types'

export function* getReferralData(action) {
  try {
    const referralDataResponse = yield call(
      api.getReferralPageData,
      action.payload.slug
    )

    yield put(actions.pageDataSuccess(referralDataResponse.data))
  } catch (error) {
    yield put(actions.pageDataFailure(error))
  }
}

export default function* referralRoot() {
  yield all([
    takeLeading<ReferralAction>(actionTypes.PAGE_DATA.GET, getReferralData),
  ])
}
