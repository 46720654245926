import { differenceInDays, differenceInHours, format } from 'date-fns'

export const getHourDifference = (currentDate: Date, articleDate: Date) => {
  const diff = differenceInHours(currentDate, articleDate)

  if (diff <= 1) {
    return `1 hour ago`
  } else {
    return `${diff} hours ago`
  }
}

export const articleAgeVerbiage = (articleAge: number, articleDate: Date) => {
  if (articleAge > 3) {
    return format(articleDate, 'dd MMMM yyyy')
  } else if (articleAge === 1) {
    return `${articleAge} day ago`
  } else if (articleAge < 1) {
    return getHourDifference(new Date(), articleDate)
  } else {
    return `${articleAge} days ago`
  }
}

export const publishedDateAge = (date: Date) => {
  return differenceInDays(new Date(), date)
}

export const articlePublishedDate = (date: string) => {
  const articleDate = new Date(date)
  const articleAge = publishedDateAge(articleDate)
  return articleAgeVerbiage(articleAge, articleDate)
}
