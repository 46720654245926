import { MEDIA_QUERIES } from '../../constants/ui'

/**
 * A function that returns the current screen size and matched/unmatched media
 * queries in human-readable format
 *
 * @returns Object - The current matched media query and whether is it smaller
 * than or equal/larger to corresponding media queries
 */
export const getSizeMatches = () => {
  let equals

  const isSmallerThan = {}
  const isEqualOrLargerThan = {}

  const winWidth = window.innerWidth

  MEDIA_QUERIES.forEach(({ name, query, minWidth }) => {
    if ((window.matchMedia && query.matches) || winWidth >= minWidth) {
      equals = name

      isEqualOrLargerThan[name] = true
      isSmallerThan[name] = false
    } else {
      isEqualOrLargerThan[name] = false
      isSmallerThan[name] = true
    }
  })

  return {
    equals,
    isSmallerThan,
    isEqualOrLargerThan,
  }
}

/**
 * Returns the current device orientation.
 *
 * @returns string - 'portrait' | 'landscape'
 */
export const getDeviceOrientation = () => {
  let orientation

  if (window.matchMedia) {
    orientation = window.matchMedia('(orientation: portrait)').matches
      ? 'portrait'
      : 'landscape'
  } else {
    orientation =
      window.innerWidth < window.innerHeight ? 'portrait' : 'landscape'
  }

  return orientation
}

/**
 * A function which, using the above functions, created a single object of
 * viewport information.
 *
 * @returns Object - Returns the viewport dimensions, orientation and
 * matched/unmatched media queries.
 */
export const getViewportInfo = () => {
  const orientation = getDeviceOrientation()
  const sizeMatches = getSizeMatches()

  return {
    orientation,
    equals: sizeMatches.equals,
    isEqualOrLargerThan: sizeMatches.isEqualOrLargerThan,
    isSmallerThan: sizeMatches.isSmallerThan,
  }
}
