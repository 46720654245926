import { actionTypes } from './actions'
import { CampaignAction, CampaignState } from './types'

export const initialState: CampaignState = {
  error: false,
  loading: false,
  pageData: undefined,
}

export default (
  state: CampaignState = initialState,
  action: CampaignAction
): CampaignState => {
  switch (action.type) {
    case actionTypes.PAGE_DATA.GET:
      return {
        ...state,
        loading: true,
      }

    case actionTypes.PAGE_DATA.SUCCESS:
      return {
        ...state,
        loading: false,
        pageData: action.payload.pageData,
      }

    case actionTypes.PAGE_DATA.FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      }

    default:
      return state
  }
}
