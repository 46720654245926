import axios from 'axios'
import csvtojson from 'csvtojson'

import { formQueryParam, generateRequest, generateRequestUrl } from '../utils'

export const URLs = {
  getAboutPageData: () => generateRequestUrl('aboutUs'),
  getAdviserLocatorSearchData: (postcode) => `/adviser/search/${postcode}`,
  getAdvisersPageData: () => `/adviser`,
  getAdvisersProfilePageData: (slug) => `/adviser/${slug}`,
  getArticle: (slug: string) => generateRequestUrl('article', slug),
  getArticleLandingPageData: () => generateRequestUrl('articleLanding'),
  getArticles: (
    limit: number,
    offset: number,
    tag?: string,
    currentArticleSlug?: string
  ) => {
    let url = `/articles/cards?limit=${limit}&skip=${offset}`
    if (tag) {
      url += `&tag=${tag}`
    }
    if (currentArticleSlug) {
      url += `&currentArticleSlug=${currentArticleSlug}`
    }
    return url
  },
  getCallbackFormPageData: () => `/form/`,
  getAppointmentSlots: () => `/form/appointment/getAppointmentSlots`,
  getAppointmentCandidates: () => `/form/appointment/getAppointmentCandidates`,
  getEmployerFormPageData: () => `/form/employer`,
  getCampaignPageData: (slug: string) => generateRequestUrl('campaign', slug),
  getCustomSlugPageData: (parentSlug: string, slug: string) =>
    generateRequestUrl('customSlugPage', slug, parentSlug),
  getReferralPageData: (slug: string) => generateRequestUrl('employer', slug),
  getReferPageData: (slug: string) => generateRequestUrl('refer', slug),
  getCommonReportingStandardPageData: () =>
    generateRequestUrl('regulatory', 'common-reporting-standard'),
  getContactPageData: () => generateRequestUrl('contactUs'),
  getCookiePolicyPageData: () =>
    generateRequestUrl('regulatory', 'cookie-policy'),
  getFaqPageData: () => generateRequestUrl('helpCentre'),
  getFundInfoPageData: () => generateRequestUrl('fundInfo'),
  getGlobalData: () => generateRequestUrl('globalTemplate'),
  getHomePageData: () => generateRequestUrl('home'),
  getHowItWorksPageData: () => generateRequestUrl('howItWorks'),
  getLegalInformationPageData: () =>
    generateRequestUrl('regulatory', 'legal-information'),
  getModernSlaveryActPageData: () =>
    generateRequestUrl('regulatory', 'modern-slavery-act'),
  getShareDealingPageData: () =>
    generateRequestUrl('regulatory', 'share-dealing'),
  getMediaCentrePageData: () => '/content/?type=mediaCentre',
  getPreferences: (id: string, type: string) =>
    `/form/preferences?id=${encodeURI(id)}&type=${type}`,
  getPricingPageData: () => generateRequestUrl('pricing'),
  getPrivacyPolicyPageData: () =>
    generateRequestUrl('regulatory', 'privacy-policy'),
  getProfilingToolPageData: () =>
    `${generateRequestUrl('profiling')}&include=2`,
  getProfilingToolResultsPageData: (answers: string) =>
    `/content/profileResult?${formQueryParam({ answers })}`,
  getSecurityPageData: () => generateRequestUrl('regulatory', 'security'),
  getTermsAndConditionsPageData: () =>
    generateRequestUrl('regulatory', 'terms-and-conditions'),
  getWhatWeDoPageData: () => generateRequestUrl('whatWeDo'),
  getWorkWithUsPageData: () => generateRequestUrl('workWithUs'),
  getRedirects: () => generateRequestUrl('redirectMappings'),
  logging: () => `/logging`,
  postCallbackForm: () => `/form/callback`,
  postEmployerForm: () => `/form/employer`,
  postReferForm: () => `/form/refer`,
  postQuizForm: () => `/form/quiz`,
  postGatedForm: () => `/form/gated`,
  postNewsLetterSignup: () => `/form/newsletter`,
  savePreferences: () => `/form/preferences`,
  saveMarketingConsents: () => `/form/marketingconsents`,
  getUtmBranding: (utm: string) => `/content/utmBranding?utm=${utm}`,
}

export default {
  getAboutPageData() {
    return generateRequest('get', 'getAboutPageData', URLs.getAboutPageData())
  },

  getAdvisersPageData() {
    return generateRequest(
      'get',
      'getAdvisersPageData',
      URLs.getAdvisersPageData()
    )
  },

  getAdviserLocatorSearchData(postcode: string) {
    return generateRequest(
      'get',
      'getAdviserLocatorSearchData',
      URLs.getAdviserLocatorSearchData(postcode)
    )
  },

  getAdviserProfilePageData(slug: string) {
    return generateRequest(
      'get',
      'getAdviserProfilePageData',
      URLs.getAdvisersProfilePageData(slug)
    )
  },

  getArticleData(slug: string) {
    return generateRequest('get', 'getArticleData', URLs.getArticle(slug))
  },

  getArticleLandingPageData() {
    return generateRequest(
      'get',
      'getArticleLandingPageData',
      URLs.getArticleLandingPageData()
    )
  },

  getArticles(
    limit: number,
    offset: number,
    tag?: string,
    currentArticleSlug?: string
  ) {
    return generateRequest(
      'get',
      'getArticles',
      URLs.getArticles(limit, offset, tag, currentArticleSlug)
    )
  },

  getCallbackFormPageData() {
    return generateRequest(
      'get',
      'getCallbackFormPageData',
      URLs.getCallbackFormPageData()
    )
  },

  getAppointmentSlots(sfSchedulingData) {
    return generateRequest(
      'post',
      'getAppointmentSlots',
      URLs.getAppointmentSlots(),
      sfSchedulingData
    )
  },

  getAppointmentCandidates(sfSchedulingData) {
    return generateRequest(
      'post',
      'getAppointmentCandidates',
      URLs.getAppointmentCandidates(),
      sfSchedulingData
    )
  },

  getEmployerFormPageData() {
    return generateRequest(
      'get',
      'getEmployerFormPageData',
      URLs.getEmployerFormPageData()
    )
  },

  getCampaignPageData(slug: string) {
    return generateRequest(
      'get',
      'getCampaignPageData',
      URLs.getCampaignPageData(slug)
    )
  },

  getCustomSlugPageData(parentSlug: string, slug: string) {
    return generateRequest(
      'get',
      'getCustomSlugPageData',
      URLs.getCustomSlugPageData(parentSlug, slug)
    )
  },

  getReferralPageData(slug: string) {
    return generateRequest(
      'get',
      'getReferralPageData',
      URLs.getReferralPageData(slug)
    )
  },

  getReferPageData(slug: string) {
    return generateRequest(
      'get',
      'getReferPageData',
      URLs.getReferPageData(slug)
    )
  },

  getCommonReportingStandardPageData() {
    return generateRequest(
      'get',
      'getCommonReportingStandardPageData',
      URLs.getCommonReportingStandardPageData()
    )
  },

  getContactPageData() {
    return generateRequest(
      'get',
      'getContactPageData',
      URLs.getContactPageData()
    )
  },

  getCookiePolicyPageData() {
    return generateRequest(
      'get',
      'getCookiePolicyPageData',
      URLs.getCookiePolicyPageData()
    )
  },

  getFaqPageData() {
    return generateRequest('get', 'getFaqPageData', URLs.getFaqPageData())
  },

  getFundInfoPageData() {
    return generateRequest(
      'get',
      'getFundInfoPageData',
      URLs.getFundInfoPageData()
    )
  },

  getGlobalData() {
    return generateRequest('get', 'getGlobalData', URLs.getGlobalData())
  },
  getHomePageData() {
    return generateRequest('get', 'getHomePageData', URLs.getHomePageData())
  },

  getHowItWorksPageData() {
    return generateRequest(
      'get',
      'getHowItWorksPageData',
      URLs.getHowItWorksPageData()
    )
  },

  getLegalInformationPageData() {
    return generateRequest(
      'get',
      'getLegalInformationPageData',
      URLs.getLegalInformationPageData()
    )
  },

  getModernSlaveryActPageData() {
    return generateRequest(
      'get',
      'getModernSlaveryActPageData',
      URLs.getModernSlaveryActPageData()
    )
  },

  getShareDealingPageData() {
    return generateRequest(
      'get',
      'getShareDealingPageData',
      URLs.getShareDealingPageData()
    )
  },

  getMediaCentrePageData() {
    return generateRequest(
      'get',
      'getMediaCentrePageData',
      URLs.getMediaCentrePageData()
    )
  },

  getPreferences(id: string, type: string) {
    const url = URLs.getPreferences(encodeURIComponent(id), type)
    return generateRequest('get', 'getPreferences', url)
  },

  getPricingPageData() {
    return generateRequest(
      'get',
      'getPricingPageData',
      URLs.getPricingPageData()
    )
  },

  getPrivacyPolicyPageData() {
    return generateRequest(
      'get',
      'getPrivacyPolicyPageData',
      URLs.getPrivacyPolicyPageData()
    )
  },

  getProfilingToolPageData() {
    return generateRequest(
      'get',
      'getProfilingToolPageData',
      URLs.getProfilingToolPageData()
    )
  },

  getProfilingToolResultsPageData(answers: string) {
    return generateRequest(
      'get',
      'getProfilingToolResultsPageData',
      URLs.getProfilingToolResultsPageData(answers)
    )
  },

  getRedirects() {
    return generateRequest('get', 'getRedirects', URLs.getRedirects())
  },

  getSecurityPageData() {
    return generateRequest(
      'get',
      'getPrivacyPolicyPageData',
      URLs.getSecurityPageData()
    )
  },

  getTermsAndConditionsPageData() {
    return generateRequest(
      'get',
      'getTermsAndConditionsPageData',
      URLs.getTermsAndConditionsPageData()
    )
  },

  getWhatWeDoPageData() {
    return generateRequest(
      'get',
      'getWhatWeDoPageData',
      URLs.getWhatWeDoPageData()
    )
  },

  getWorkWithUsPageData() {
    return generateRequest(
      'get',
      'getWorkWithUsPageData',
      URLs.getWorkWithUsPageData()
    )
  },

  getUtmBrandingData(utm: string) {
    return generateRequest('get', 'getUtmBranding', URLs.getUtmBranding(utm))
  },

  loadAndConvertCsvFile(url: string): Promise<any> {
    return axios
      .get(`https://${url}`)
      .then((response) => {
        const value = csvtojson({
          noheader: true,
          output: 'csv',
        }).fromString(response.data)
        return value
      })
      .catch((error) => {
        // tslint:disable-next-line: no-console
        console.log(error)
        throw error
      })
  },

  log(postData) {
    return axios.post(URLs.logging(), postData).catch((e) => {
      // tslint:disable-next-line: no-console
      console.error(e)
    })
  },

  postCallbackForm(postData) {
    return generateRequest(
      'post',
      'postCallbackForm',
      URLs.postCallbackForm(),
      postData
    )
  },

  postEmployerForm(postData) {
    return generateRequest(
      'post',
      'postEmployerForm',
      URLs.postEmployerForm(),
      postData
    )
  },

  postReferForm(postData) {
    return generateRequest(
      'post',
      'postReferForm',
      URLs.postReferForm(),
      postData
    )
  },

  postNewsLetterSignup(postData) {
    return generateRequest(
      'post',
      'postNewsLetterSignup',
      URLs.postNewsLetterSignup(),
      postData
    )
  },

  postQuizForm(postData) {
    return generateRequest(
      'post',
      'postQuizForm',
      URLs.postQuizForm(),
      postData
    )
  },

  postGatedForm(postData) {
    return generateRequest(
      'post',
      'postGatedForm',
      URLs.postGatedForm(),
      postData
    )
  },

  savePreferences(data) {
    return generateRequest(
      'post',
      'savePreferences',
      URLs.savePreferences(),
      data
    )
  },

  saveMarketingConsents(data) {
    return generateRequest(
      'post',
      'saveMarketingConsents',
      URLs.saveMarketingConsents(),
      data
    )
  },
}
