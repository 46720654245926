const isSupported = (browser) => {
  if (
    (browser.name === 'MSIE' || browser.name === 'IE') &&
    browser.version <= 11
  ) {
    return false
  }
  return true
}

const getBrowser = () => {
  const ua = window.navigator.userAgent
  let tem
  let browserDetails =
    ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) ||
    []

  if (/trident/i.test(browserDetails[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || []
    return { name: 'IE', version: tem[1] || '' }
  }

  if (window.navigator.userAgent.indexOf('Edge') > -1) {
    tem = ua.match(/\Edge\/(\d+)/)
    if (tem != null) {
      return { name: 'Edge', version: tem[1] }
    }
  }

  if (browserDetails[1] === 'Chrome') {
    tem = ua.match(/\bOPR\/(\d+)/)
    if (tem != null) {
      return { name: 'Opera', version: tem[1] }
    }
  }

  browserDetails = browserDetails[2]
    ? [browserDetails[1], browserDetails[2]]
    : [navigator.appName, navigator.appVersion, '-?']
  tem = ua.match(/version\/(\d+)/i)

  if (tem != null) {
    browserDetails.splice(1, 1, tem[1])
  }

  return {
    name: browserDetails[0],
    version: +browserDetails[1],
  }
}

export const isBrowserSupported = () => {
  if (typeof window === 'undefined') {
    return false
  } else {
    return isSupported(getBrowser())
  }
}
