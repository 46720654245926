import { RedirectMap, RedirectMappings } from '../redirects/types'
import api from '../services/api'
import cacheData from 'memory-cache'
const REDIRECTS_CACHE_KEY = 'redirects'

/**
 * A function that retrieves redirects from Contentful and caches them
 *
 * @param path - current page path
 */

export const matchPathToRedirectMap = async (
  path: string,
  cache: cacheData
): Promise<RedirectMap> => {
  try {
    // use content API endpoint to get redirects
    const redirectMappings = await getRedirectMappings(cache)
    if (
      !redirectMappings ||
      !redirectMappings.redirectsEnabled ||
      !redirectMappings.redirects
    ) {
      return undefined
    }
    // try and match sourceUrl
    return redirectMappings.redirects.find(function (item) {
      return item.sourceUrl.toLowerCase() === path.toLowerCase()
    })
  } catch (error) {
    console.error(error)
    return undefined
  }
}

const getRedirectMappings = async (
  cache: cacheData
): Promise<RedirectMappings> => {
  if (!cache.get(REDIRECTS_CACHE_KEY)) {
    const apiResponse = await api.getRedirects()
    if (apiResponse.data && apiResponse.data.items) {
      const redirectMappings = apiResponse.data.items[0] as RedirectMappings
      cache.put(
        REDIRECTS_CACHE_KEY,
        redirectMappings,
        redirectMappings.cacheDuration
      )
    }
  }
  return cache.get(REDIRECTS_CACHE_KEY)
}
