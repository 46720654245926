import { all, call, put, takeLeading } from 'redux-saga/effects'

import api from '../../../services/api'

import { actions, actionTypes } from './actions'
import { FaqAction } from './types'

export function* getFaqData() {
  try {
    const faqDataResponse = yield call(api.getFaqPageData)

    yield put(actions.pageDataSuccess(faqDataResponse.data))
  } catch (error) {
    yield put(actions.pageDataFailure(error))
  }
}

export default function* faqRoot() {
  yield all([takeLeading<FaqAction>(actionTypes.PAGE_DATA.GET, getFaqData)])
}
