import { all, call, put, takeLeading } from 'redux-saga/effects'

import api from '../../../services/api'

import { actions, actionTypes } from './actions'
import { AboutAction } from './types'

export function* getAboutData() {
  try {
    const aboutDataResponse = yield call(api.getAboutPageData)

    yield put(actions.pageDataSuccess(aboutDataResponse.data))
  } catch (error) {
    yield put(actions.pageDataFailure(error))
  }
}

export default function* aboutRoot() {
  yield all([takeLeading<AboutAction>(actionTypes.PAGE_DATA.GET, getAboutData)])
}
