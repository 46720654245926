import colors from '../colors'

export const callToActionTertiary = {
  active: {
    textThemeColor: colors.text.white,
    backgroundThemeColor: colors.background.black,
    borderThemeColor: colors.background.grey7,
    backgroundHoverThemeColor: colors.background.grey7,
  },
  disabled: {
    textThemeColor: colors.background.black,
    backgroundThemeColor: colors.background.grey2,
    borderThemeColor: colors.ui.grey2,
  },
}

// Used by Rocketboy and red
export const callToActionSecondary = {
  active: {
    textThemeColor: colors.text.red,
    backgroundThemeColor: colors.background.grey1,
    backgroundHoverThemeColor: colors.background.white,
    borderThemeColor: colors.ui.red,
  },
  disabled: {
    textThemeColor: colors.background.grey2,
    backgroundThemeColor: colors.background.white,
    borderThemeColor: colors.ui.grey2,
  },
}
