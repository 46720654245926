import { actionTypes } from './actions'
import { GlobalAction, GlobalState } from './types'

export const initialState: GlobalState = {
  header: {
    data: {
      utilityMenu: undefined,
      companyLogo: undefined,
      primaryMenu: undefined,
      secondaryMenu: undefined,
      callToAction: undefined,
    },
    status: 'visible',
  },
  headerSlim: {
    data: {
      backToHomeAction: undefined,
      companyLogo: undefined,
      callbackModalAction: undefined,
    },
  },
  footer: {
    data: undefined,
  },
  viewportInfo: {
    orientation: undefined,
    equals: undefined,
    isEqualOrLargerThan: {
      mobile: undefined,
      tablet: undefined,
      desktop: undefined,
      desktopMedium: undefined,
      desktopLarge: undefined,
      desktopExtraLarge: undefined,
    },
    isSmallerThan: {
      mobile: undefined,
      tablet: undefined,
      desktop: undefined,
      desktopMedium: undefined,
      desktopLarge: undefined,
      desktopExtraLarge: undefined,
    },
  },
  selectedArticles: undefined,
  browserNotSupportedMessage: {
    data: undefined,
    loading: true,
  },
}

export default (
  state: GlobalState = initialState,
  action: GlobalAction
): GlobalState => {
  switch (action.type) {
    case actionTypes.GLOBAL_DATA.GET:
      return {
        ...state,
        header: {
          ...state.header,
          loading: true,
        },
        headerSlim: {
          ...state.headerSlim,
          loading: true,
        },
        footer: {
          ...state.footer,
          loading: true,
        },
        browserNotSupportedMessage: {
          ...state.browserNotSupportedMessage,
          loading: true,
        },
      }

    case actionTypes.GLOBAL_DATA.SUCCESS:
      return {
        ...state,
        header: {
          ...state.header,
          data: action.payload.globalData.header[0],
          loading: false,
        },
        headerSlim: {
          ...state.headerSlim,
          data: action.payload.globalData.headerSlim[0],
          loading: false,
        },
        footer: {
          ...state.footer,
          data: action.payload.globalData.footer[0],
          loading: false,
        },
        browserNotSupportedMessage: {
          ...state.browserNotSupportedMessage,
          data: action.payload.globalData.browserNotSupportedMessage,
          loading: false,
        },
      }

    case actionTypes.GLOBAL_DATA.FAILURE:
      return {
        ...state,
        header: {
          ...state.header,
          loading: false,
          error: true,
        },
        headerSlim: {
          ...state.headerSlim,
          loading: false,
          error: true,
        },
        footer: {
          ...state.footer,
          loading: false,
          error: true,
        },
        browserNotSupportedMessage: {
          ...state.browserNotSupportedMessage,
          loading: true,
        },
      }

    case actionTypes.VIEWPORT_INFO.SET:
      return {
        ...state,
        viewportInfo: {
          ...state.viewportInfo,
          ...action.payload.viewportInfo,
        },
      }

    case actionTypes.HEADER_STATUS.SET:
      return {
        ...state,
        header: {
          ...state.header,
          status: action.payload.status,
        },
      }

    case actionTypes.SELECTED_ARTICLES.GET:
      return {
        ...state,
        selectedArticles: {
          ...state.selectedArticles,
          filteredData: Boolean(
            action.payload.selectedArticles.currentArticleSlug
          ),
          loading: true,
        },
      }

    case actionTypes.SELECTED_ARTICLES.SUCCESS:
      return {
        ...state,
        selectedArticles: {
          ...state.selectedArticles,
          loading: false,
          data: action.payload.selectedArticles.data,
        },
      }

    case actionTypes.SELECTED_ARTICLES.FAILURE:
      return {
        ...state,
        selectedArticles: {
          ...state.selectedArticles,
          error: action.payload.selectedArticles.error,
          loading: false,
        },
      }
    default:
      return state
  }
}
