import { all, call, put, takeLeading } from 'redux-saga/effects'

import api from '../../../services/api'

import { actions, actionTypes } from './actions'
import { CampaignAction } from './types'

export function* getCampaignData(action) {
  try {
    const campaignDataResponse = yield call(
      api.getCampaignPageData,
      action.payload.slug
    )

    yield put(actions.pageDataSuccess(campaignDataResponse.data))
  } catch (error) {
    yield put(actions.pageDataFailure(error))
  }
}

export default function* campaignRoot() {
  yield all([
    takeLeading<CampaignAction>(actionTypes.PAGE_DATA.GET, getCampaignData),
  ])
}
