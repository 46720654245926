import { PROFILING_TOOL_RESULTS_REDUCER_NAMESPACE } from '../../../constants'
import { namespacer } from '../../../utils'

import { ProfilingToolResultsAction } from './types'

const namespacedAction = namespacer(PROFILING_TOOL_RESULTS_REDUCER_NAMESPACE)

export const actionTypes = {
  PAGE_DATA: {
    GET: namespacedAction('PAGE_DATA_GET'),
    FAILURE: namespacedAction('PAGE_DATA_FAILURE'),
    SUCCESS: namespacedAction('PAGE_DATA_SUCCESS'),
  },
}

export const actions = {
  pageDataGet(answers: string): ProfilingToolResultsAction {
    return {
      payload: { answers },
      type: actionTypes.PAGE_DATA.GET,
    }
  },
  pageDataSuccess(pageData): ProfilingToolResultsAction {
    return {
      payload: {
        pageData,
      },
      type: actionTypes.PAGE_DATA.SUCCESS,
    }
  },
  pageDataFailure(error): ProfilingToolResultsAction {
    return {
      payload: {
        error,
      },
      type: actionTypes.PAGE_DATA.FAILURE,
    }
  },
}
