// Breakpoints are mobile first, in the direction as follows:
// | Mobile  | Tablet    | Desktop     | Desktop Large | Desktop big screen
// | 0 > 767 | 768 > 991 | 992 -> 1199 | 1200+         | 1920+
export const deviceSizes = {
  mobile: 320,
  tablet: 768,
  desktop: 1024,
  desktopMedium: 1200,
  desktopLarge: 1280,
  desktopExtraLarge: 1440,
  desktopXXL: 1920,
}

export default {
  mobileMax: `(max-width: ${deviceSizes.tablet - 1}px)`,
  tablet: `(min-width: ${deviceSizes.tablet}px)`,
  tabletMax: `(max-width: ${deviceSizes.desktop - 1}px)`,
  tabletOnly: `(min-width: ${deviceSizes.tablet}px) and (max-width: ${
    deviceSizes.desktop - 1
  }px)`,
  desktop: `(min-width: ${deviceSizes.desktop}px)`,
  desktopMax: `(max-width: ${deviceSizes.desktopLarge - 1}px)`,
  desktopMedium: `(min-width: ${deviceSizes.desktopMedium}px)`,
  desktopMediumMax: `(max-width: ${deviceSizes.desktopMedium - 1}px)`,
  desktopLarge: `(min-width: ${deviceSizes.desktopLarge}px)`,
  desktopExtraLarge: `(min-width: ${deviceSizes.desktopExtraLarge}px)`,
  desktopXXL: `(min-width: ${deviceSizes.desktopXXL}px)`,
}
