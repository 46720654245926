import { all, call, put, takeLeading } from 'redux-saga/effects'

import api from '../../../services/api'

import { actions, actionTypes } from './actions'
import { LoadAction, SaveAction, UnsubscribeAction } from './types'

export function* load(action: LoadAction) {
  try {
    const preferenceCentreDataResponse = yield call(
      api.getPreferences,
      action.payload.id,
      action.payload.type
    )

    yield put(actions.loadSuccess(preferenceCentreDataResponse.data))
  } catch (error) {
    yield put(actions.loadFailure(error))
  }
}

export function* saveMarketingConsents(action: SaveAction) {
  try {
    //remove consent from payload
    const apiPayload = action.payload
    apiPayload.SPW_Marketing_Consents__c = undefined
    yield call(api.saveMarketingConsents, apiPayload)

    yield put(actions.saveSuccess(action.payload))
  } catch (error) {
    yield put(actions.saveFailure(error))
  }
}

export function* unsubscribeMarketingConsents(action: UnsubscribeAction) {
  try {
    yield call(api.saveMarketingConsents, action.payload)

    yield put(actions.unsubscribeSuccess(action.payload))
  } catch (error) {
    yield put(actions.unsubscribeFailure(error))
  }
}

export default function* preferencesRoot() {
  yield all([
    takeLeading<LoadAction>(actionTypes.LOAD, load),
    takeLeading<SaveAction>(
      actionTypes.SAVE_MARKETING_CONSENTS,
      saveMarketingConsents
    ),
    takeLeading<UnsubscribeAction>(
      actionTypes.UNSUBSCRIBE_MARKETING_CONSENTS,
      unsubscribeMarketingConsents
    ),
  ])
}
