import { datadogRum, DefaultPrivacyLevel } from '@datadog/browser-rum'
import { datadogLogs } from '@datadog/browser-logs'
import getConfig from 'next/config'

const { publicRuntimeConfig } = getConfig()

if (publicRuntimeConfig.DATADOG_ENABLED === 'true') {
  datadogRum.init({
    applicationId: publicRuntimeConfig.DATADOG_APPLICATION_ID,
    clientToken: publicRuntimeConfig.DATADOG_CLIENT_TOKEN,
    service: 'spw.com',
    env: publicRuntimeConfig.DATADOG_ENV,
    site: 'datadoghq.eu',
    version: process.env.NEXT_PUBLIC_APP_VERSION,
    allowedTracingUrls: [
      (url: string) =>
        url.includes('spw.com') || url.includes('spw.technology'),
    ],
    defaultPrivacyLevel:
      (publicRuntimeConfig.DATADOG_DEFAULT_PRIVACY_LEVEL as DefaultPrivacyLevel) ||
      DefaultPrivacyLevel.MASK_USER_INPUT,
    sampleRate: Number(publicRuntimeConfig.DATADOG_RUM_SAMPLE_RATE) || 100,
    sessionReplaySampleRate:
      Number(publicRuntimeConfig.DATADOG_SESSION_REPLAY_SAMPLE_RATE) || 100,
    traceSampleRate:
      Number(publicRuntimeConfig.DATADOG_TRACE_SAMPLE_RATE) || 100,
    telemetrySampleRate: 0,
    trackFrustrations: true,
    trackLongTasks: true,
    trackResources: true,
    trackUserInteractions: true,
    useSecureSessionCookie: true,
  })

  datadogLogs.init({
    clientToken: publicRuntimeConfig.DATADOG_CLIENT_TOKEN,
    service: 'spw.com',
    env: publicRuntimeConfig.DATADOG_ENV,
    site: 'datadoghq.eu',
    version: process.env.NEXT_PUBLIC_APP_VERSION,
    forwardConsoleLogs: 'all',
    forwardErrorsToLogs: true,
    forwardReports: 'all',
    sampleRate: Number(publicRuntimeConfig.DATADOG_LOGS_SAMPLE_RATE) || 100,
    telemetrySampleRate: 0,
    useSecureSessionCookie: true,
  })

  datadogRum.startSessionReplayRecording()
}
