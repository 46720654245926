import mediaQueries, { deviceSizes } from '../styles/theme/breakpoints'

export const APP_DEFAULT_THEME_COLOUR = 'rocketboy'

export const MEDIA_QUERIES = [
  {
    name: 'mobile',
    query: '(min-width: 0px)',
    minWidth: 0,
  },
  {
    name: 'tablet',
    query: mediaQueries.tablet,
    minWidth: deviceSizes.tablet,
  },
  {
    name: 'desktop',
    query: mediaQueries.desktop,
    minWidth: deviceSizes.desktop,
  },
  {
    name: 'desktopMedium',
    query: mediaQueries.desktopMediumMax,
    minWidth: deviceSizes.desktopMedium,
  },
  {
    name: 'desktopLarge',
    query: mediaQueries.desktopLarge,
    minWidth: deviceSizes.desktopLarge,
  },
  {
    name: 'desktopExtraLarge',
    query: mediaQueries.desktopExtraLarge,
    minWidth: deviceSizes.desktopExtraLarge,
  },
]

export const HEADER_HEIGHTS = {
  mobile: 70,
  tablet: 100,
  desktop: 133,
  desktopLarge: 133,
  desktopExtraLarge: 133,
}

export const SKIP_TO_CONTENT_ZINDEX = 11
