import { all, call, put, takeLeading } from 'redux-saga/effects'

import api from '../../../services/api'

import { actions, actionTypes } from './actions'
import { ReferAction } from './types'

export function* getReferData(action) {
  try {
    const referDataResponse = yield call(
      api.getReferPageData,
      action.payload.slug
    )

    yield put(actions.pageDataSuccess(referDataResponse.data))
  } catch (error) {
    yield put(actions.pageDataFailure(error))
  }
}

export default function* referRoot() {
  yield all([takeLeading<ReferAction>(actionTypes.PAGE_DATA.GET, getReferData)])
}
