import baseStyled, { ThemedStyledInterface } from 'styled-components'

import animations from './animations'
import breakpoints, { deviceSizes } from './breakpoints'
import colors from './colors'
import colorThemes from './colorThemes'
import dimensions from './dimensions'
import fontAttributes from './fontAttributes'
import transitions from './transitions'
import zIndex from './zIndex'

const {
  baseFontSizePercentage,
  fontFamily,
  fontSizes,
  fontWeights,
  letterSpacings,
  lineHeights,
} = fontAttributes

const theme = {
  baseFontSizePercentage,
  breakpoints,
  letterSpacings,
  deviceSizes,
  dimensions,
  colors,
  colorThemes,
  transitions,
  fontFamily,
  fontSizes,
  fontWeights,
  lineHeights,
  animations,
  zIndex,
}

export default theme
export type Theme = typeof theme
export const styled = baseStyled as ThemedStyledInterface<Theme>
