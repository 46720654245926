import axios from 'axios'

import { GenerateRequest } from './types'

/**
 * A function that creates a API request with passed method, endpoint and
 * arguments. Also produces a console.log when in a non-production setting for
 * debugging of requests.
 *
 * @param method - Required. The type of method (e.g. GET / POST)
 * @param request - Required. The name of the request being made
 * @param apiUrl - Required. The apiUrl to use in the request
 * @param args - Any arguments to be passed for POST requests
 */
export const generateRequest: GenerateRequest = (
  method,
  request,
  api,
  args
) => {
  const isPreviewMode = Boolean(
    axios.defaults.baseURL.indexOf('preview') !== -1
  )

  if (process.env.NODE_ENV !== 'production') {
    // tslint:disable-next-line: no-console
    console.log(
      `[ ${axios.defaults.baseURL}${api} | ${method.toUpperCase()} |${
        isPreviewMode ? ' preview |' : ''
      } ${request} ]`
    )
  }

  return args ? axios[method](api, args) : axios[method](api)
}
