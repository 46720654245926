/**
 * Returns value to be used for the target property of an anchor element
 * @param target : target defined in contentful
 */
export const getTargetWindow = (target: string): string => {
  if (target === 'new window') {
    return '_blank'
  }
  return '_self'
}
