import { CALLBACK_FORM_REDUCER_NAMESPACE } from '../../../../constants'
import { namespacer } from '../../../../utils'

const namespacedAction = namespacer(CALLBACK_FORM_REDUCER_NAMESPACE)

export const actionTypes = {
  BACK: namespacedAction('BACK'),
  OPEN: namespacedAction('OPEN'),
  CLOSE: namespacedAction('CLOSE'),
  FAILURE: namespacedAction('FAILURE'),
  SUCCESS: namespacedAction('SUCCESS'),
  LOAD: namespacedAction('LOAD'),
  LOAD_FAILURE: namespacedAction('LOAD_FAILURE'),
  LOAD_SUCCESS: namespacedAction('LOAD_SUCCESS'),
  LOAD_TIMESLOTS_SUCCESS: namespacedAction('LOAD_TIMESLOTS_SUCCESS'),
  LOAD_CANDIDATES_SUCCESS: namespacedAction('LOAD_CANDIDATES_SUCCESS'),
  SAVE: namespacedAction('SAVE_DATA'),
  SAVE_SUCCESS: namespacedAction('SAVE_SUCCESS'),
  SAVE_FAILURE: namespacedAction('SAVE_FAILURE'),
}

export const actions = {
  showCallbackFormModal() {
    return {
      payload: {
        visible: true,
      },
      type: actionTypes.OPEN,
    }
  },

  load() {
    return {
      payload: {
        loading: true,
      },
      type: actionTypes.LOAD,
    }
  },

  loadSuccess(formData) {
    return {
      payload: {
        formData,
        error: false,
        success: false,
        loading: false,
        errorMessage: undefined,
        submitResponse: undefined,
      },
      type: actionTypes.LOAD_SUCCESS,
    }
  },

  loadTimeSlotsSuccess(timeSlots) {
    return {
      payload: {
        timeSlots,
        error: false,
        success: false,
        loading: false,
        errorMessage: undefined,
        submitResponse: undefined,
      },
      type: actionTypes.LOAD_TIMESLOTS_SUCCESS,
    }
  },

  loadCandidatesSuccess(candidates) {
    return {
      payload: {
        candidates,
        error: false,
        success: false,
        loading: false,
        errorMessage: undefined,
        submitResponse: undefined,
      },
      type: actionTypes.LOAD_CANDIDATES_SUCCESS,
    }
  },

  loadFailure(error, errorMessage) {
    return {
      payload: {
        error,
        errorMessage,
        loading: false,
        success: false,
      },
      type: actionTypes.LOAD_FAILURE,
    }
  },

  back() {
    return {
      payload: {},
      type: actionTypes.BACK,
    }
  },

  save(formInput) {
    return {
      payload: {
        formInput,
      },
      type: actionTypes.SAVE,
    }
  },

  saveSuccess(response) {
    return {
      payload: {
        submitResponse: response,
      },
      type: actionTypes.SAVE_SUCCESS,
    }
  },

  saveFailure(error) {
    return {
      payload: {
        error,
      },
      type: actionTypes.SAVE_FAILURE,
    }
  },

  close() {
    return {
      payload: {
        visible: false,
      },
      type: actionTypes.CLOSE,
    }
  },
}
