/**
 * Reducer Constants
 */
export const ABOUT_REDUCER_NAMESPACE = 'about'
export const ADVISER_PROFILE_REDUCER_NAMESPACE = 'adviserProfile'
export const ADVISERS_REDUCER_NAMESPACE = 'advisers'
export const ARTICLE_REDUCER_NAMESPACE = 'article'
export const CALLBACK_FORM_REDUCER_NAMESPACE = 'callbackForm'
export const CALLBACK_FORM_IN_PAGE_REDUCER_NAMESPACE = 'callbackForm'
export const CAMPAIGN_REDUCER_NAMESPACE = 'campaign'
export const CUSTOM_SLUG_REDUCER_NAMESPACE = 'customSlug'
export const REFERRAL_REDUCER_NAMESPACE = 'referral'
export const REFER_REDUCER_NAMESPACE = 'refer'
export const CONTACT_REDUCER_NAMESPACE = 'contact'
export const FAQ_REDUCER_NAMESPACE = 'faq'
export const FUND_INFO_REDUCER_NAMESPACE = 'fundInfo'
export const GLOBAL_REDUCER_NAMESPACE = 'global'
export const HOME_REDUCER_NAMESPACE = 'home'
export const HOW_IT_WORKS_REDUCER_NAMESPACE = 'howItWorks'
export const MEDIA_CENTRE_REDUCER_NAMESPACE = 'mediaCentre'
export const PREFERENCES_CENTRE_REDUCER_NAMESPACE = 'preferenceCentre'
export const PRICING_REDUCER_NAMESPACE = 'pricing'
export const PROFILING_TOOL_REDUCER_NAMESPACE = 'profilingTool'
export const PROFILING_TOOL_RESULTS_REDUCER_NAMESPACE = 'profilingToolResults'
export const SIGNUP_BANNER_REDUCER_NAMESPACE = 'signupBanner'
export const WEALTH_COACH_REDUCER_NAMESPACE = 'knowledgeCentre'
export const WHAT_WE_DO_REDUCER_NAMESPACE = 'whatWeDo'
export const WORK_WITH_US_REDUCER_NAMESPACE = 'workWithUs'

/**
 * String constants
 */
export const POSTCODE_ERROR_MESSAGE = 'Please enter a valid postcode'

/**
 * Number constants
 */
export const ANCHOR_NAV_TOP_OFFSET_BREAKPOINT = 101
