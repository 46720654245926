import { all, call, put, takeLeading } from 'redux-saga/effects'

import api from '../../../services/api'

import { actions, actionTypes } from './actions'
import { ProfilingToolResultsAction } from './types'

export function* getProfilingToolResultsData(action) {
  try {
    const profilingToolResultsDataResponse = yield call(
      api.getProfilingToolResultsPageData,
      action.payload.answers
    )

    yield put(actions.pageDataSuccess(profilingToolResultsDataResponse.data))
  } catch (error) {
    yield put(actions.pageDataFailure(error))
  }
}

export default function* profilingToolResultsRoot() {
  yield all([
    takeLeading<ProfilingToolResultsAction>(
      actionTypes.PAGE_DATA.GET,
      getProfilingToolResultsData
    ),
  ])
}
