import { PREFERENCES_CENTRE_REDUCER_NAMESPACE } from '../../../constants'
import { namespacer } from '../../../utils'

import {
  LoadAction,
  LoadResultAction,
  PreferencesData,
  ResetAction,
  SaveAction,
  SaveResultAction,
  UnsubscribeAction,
  UnsubscribeResultAction,
} from './types'

const namespacedAction = namespacer(PREFERENCES_CENTRE_REDUCER_NAMESPACE)

export const actionTypes = {
  LOAD: namespacedAction('LOAD'),
  LOAD_FAILURE: namespacedAction('LOAD_FAILURE'),
  LOAD_SUCCESS: namespacedAction('LOAD_SUCCESS'),
  SAVE: namespacedAction('SAVE'),
  SAVE_MARKETING_CONSENTS: namespacedAction('SAVE_MARKETING_CONSENTS'),
  SAVE_FAILURE: namespacedAction('SAVE_FAILURE'),
  SAVE_SUCCESS: namespacedAction('SAVE_SUCCESS'),
  UNSUBSCRIBE: namespacedAction('UNSUBSCRIBE'),
  UNSUBSCRIBE_MARKETING_CONSENTS: namespacedAction(
    'UNSUBSCRIBE_MARKETING_CONSENTS'
  ),
  UNSUBSCRIBE_FAILURE: namespacedAction('UNSUBSCRIBE_FAILURE'),
  UNSUBSCRIBE_SUCCESS: namespacedAction('UNSUBSCRIBE_SUCCESS'),
  RESET: namespacedAction('RESET'),
}

export const actions = {
  load(id: string, type: string): LoadAction {
    return {
      payload: { id, type },
      type: actionTypes.LOAD,
    }
  },
  loadSuccess(pageData): LoadResultAction {
    return {
      payload: {
        pageData,
        error: false,
      },
      type: actionTypes.LOAD_SUCCESS,
    }
  },
  loadFailure(error): LoadResultAction {
    return {
      payload: {
        error,
      },
      type: actionTypes.LOAD_FAILURE,
    }
  },
  // TODO - Remove in SC2QR-118
  save(data: PreferencesData): SaveAction {
    return {
      payload: data,
      type: actionTypes.SAVE,
    }
  },
  saveMarketingConsents(data: PreferencesData): SaveAction {
    return {
      payload: data,
      type: actionTypes.SAVE_MARKETING_CONSENTS,
    }
  },
  saveSuccess(preferencesData: PreferencesData): SaveResultAction {
    return {
      payload: {
        error: false,
        preferencesData,
      },
      type: actionTypes.SAVE_SUCCESS,
    }
  },
  saveFailure(error: any): SaveResultAction {
    return {
      payload: {
        error,
      },
      type: actionTypes.SAVE_FAILURE,
    }
  },
  // TODO - Remove in SC2QR-118
  unsubscribe(data: PreferencesData): UnsubscribeAction {
    return {
      payload: data,
      type: actionTypes.UNSUBSCRIBE,
    }
  },
  unsubscribeMarketingConsents(data: PreferencesData): UnsubscribeAction {
    return {
      payload: data,
      type: actionTypes.UNSUBSCRIBE_MARKETING_CONSENTS,
    }
  },
  unsubscribeSuccess(
    preferencesData: PreferencesData
  ): UnsubscribeResultAction {
    return {
      payload: {
        error: false,
        preferencesData,
      },
      type: actionTypes.UNSUBSCRIBE_SUCCESS,
    }
  },
  unsubscribeFailure(error: any): UnsubscribeResultAction {
    return {
      payload: {
        error,
      },
      type: actionTypes.UNSUBSCRIBE_FAILURE,
    }
  },
  reset(saved: boolean, unsubscribed: boolean): ResetAction {
    return {
      payload: {
        saved,
        unsubscribed,
      },
      type: actionTypes.RESET,
    }
  },
}
