import getConfig from 'next/config'

const myConfig = getConfig() && getConfig().publicRuntimeConfig

export const EnvConfig = {
  SPW_UI_API_URL: myConfig ? myConfig.SPW_UI_API_URL : '',
  SPW_PREVIEW_UI_API_URL: myConfig ? myConfig.SPW_PREVIEW_UI_API_URL : '',
  SPW_UI_GA_TRACKING_ID: myConfig ? myConfig.SPW_UI_GA_TRACKING_ID : '',
  SPW_UI_GOOGLE_CAPTCHA_V2_SITE_KEY: myConfig
    ? myConfig.SPW_UI_GOOGLE_CAPTCHA_V2_SITE_KEY
    : '',
  SPW_UI_GOOGLE_CAPTCHA_V3_SITE_KEY: myConfig
    ? myConfig.SPW_UI_GOOGLE_CAPTCHA_V3_SITE_KEY
    : '',
  SPW_UI_HIGH_CHART_URL: myConfig ? myConfig.SPW_UI_HIGH_CHART_URL : '',
  SPW_UI_SITE_DOMAIN: myConfig ? myConfig.SPW_UI_SITE_DOMAIN : '',
  SPW_UI_ONE_TRUST_AUTOBLOCK_URL: myConfig
    ? myConfig.SPW_UI_ONE_TRUST_AUTOBLOCK_URL
    : '',
  SPW_UI_ONE_TRUST_BANNER_URL: myConfig
    ? myConfig.SPW_UI_ONE_TRUST_BANNER_URL
    : '',
  SPW_UI_ONE_TRUST_BANNER_SCRIPT_KEY: myConfig
    ? myConfig.SPW_UI_ONE_TRUST_BANNER_SCRIPT_KEY
    : '',
  SPW_UI_GOOGLE_MAPS_API_KEY: myConfig
    ? myConfig.SPW_UI_GOOGLE_MAPS_API_KEY
    : '',
}
