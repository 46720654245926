import { all, call, put, takeLeading } from 'redux-saga/effects'

import api from '../../../services/api'

import { actions, actionTypes } from './actions'
import { FundInfoAction } from './types'

export function* getFundInfoData() {
  try {
    const fundInfoDataResponse = yield call(api.getFundInfoPageData)

    yield put(actions.pageDataSuccess(fundInfoDataResponse.data))
  } catch (error) {
    yield put(actions.pageDataFailure(error))
  }
}

export default function* fundInfoRoot() {
  yield all([
    takeLeading<FundInfoAction>(actionTypes.PAGE_DATA.GET, getFundInfoData),
  ])
}
