/* istanbul ignore file */
import { applyMiddleware, createStore, Store } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'
import createSagaMiddleware, { Task } from 'redux-saga'
import { HYDRATE, createWrapper } from 'next-redux-wrapper'
import rootReducer from './reducers'
import rootSaga from './sagas'
import { AppState } from './types'

export interface SagaStore extends Store<AppState> {
  sagaTask?: Task
}

const bindMiddleware = (middleware: any) => {
  if (process.env.NODE_ENV !== 'production') {
    return composeWithDevTools(applyMiddleware(...middleware))
  }
  return applyMiddleware(...middleware)
}

const reducer = (state, action) => {
  if (action.type === HYDRATE) {
    const nextState = {
      ...state, // use previous state
      ...action.payload, // apply delta from hydration
    }
    return nextState
  } else {
    return rootReducer(state, action)
  }
}

export const makeStore = () => {
  const sagaMiddleware = createSagaMiddleware()
  const store = createStore(reducer, bindMiddleware([sagaMiddleware]))
  ;(store as SagaStore).sagaTask = sagaMiddleware.run(rootSaga)
  return store
}

export const wrapper = createWrapper<Store<AppState>>(makeStore, {
  debug: process.env.NODE_ENV === 'production' ? false : true,
})
