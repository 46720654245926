export const palette = {
  blue: '#43a6fc',
  blue2: '#31235d',
  brightBlue: 'rgba(0, 157, 219, 0.08)',
  pink: '#f7edf5',
  purple: '#951b81',
  purple2: '#831671',
  purple3: '#59093d',
  yellow: '#fbba00',
  yellow2: '#edab05',
  red: '#e72169',
  red2: '#e50069',
  green: '#44a28c',
  green2: '#21856d',
  black: '#000',
  brightBlack: 'rgba(38, 38, 38, 0.4)',
  white: '#ffffff',
  grey1: '#f0f2f4',
  brightGray1: 'rgba(238, 236, 228, 0.6)',
  grey2: '#c4c0b3',
  grey3: '#f3f3f3',
  grey4: '#565656',
  grey5: '#e7e5dd',
  grey6: '#0b0b0b',
  grey7: '#363636',
  grey8: '#979797',
  grey9: '#f7f6f1',
  grey10: '#09090a',
  beige: '#e1dfd9',
  beigeMedium: '#c4c0b3',
  beigeHighlight: '#f8f7f4',
  beigeLight: '#eeece5',
  lightBlack: 'rgba(0, 0, 0, 0.2)',
  transparent: 'transparent',
}

const colors = {
  background: {
    white: palette.white,
    red: palette.red,
    red2: palette.red2,
    grey: palette.grey2,
    green: palette.green,
    green2: palette.green2,
    grey1: palette.grey1,
    grey2: palette.grey2,
    grey3: palette.grey3,
    grey4: palette.grey4,
    grey5: palette.grey5,
    grey6: palette.grey6,
    grey7: palette.grey7,
    grey9: palette.grey9,
    grey10: palette.grey10,
    pink: palette.pink,
    purple: palette.purple,
    purple2: palette.purple2,
    purple3: palette.purple3,
    blue: palette.blue,
    blue2: palette.blue2,
    brightBlue: palette.brightBlue,
    yellow: palette.yellow,
    yellow2: palette.yellow2,
    black: palette.black,
    beige: palette.beige,
    beigeHighlight: palette.beigeHighlight,
    beigeLight: palette.beigeLight,
    greyLight: palette.grey3,
    transparent: palette.transparent,
    rocketboyGradient: `linear-gradient(45deg, ${palette.blue2} 0%, ${palette.blue} 100%)`,
  },
  ui: {
    white: palette.white,
    black: palette.black,
    grey1: palette.grey1,
    grey2: palette.grey2,
    grey3: palette.grey3,
    grey4: palette.grey4,
    grey5: palette.grey5,
    grey6: palette.grey6,
    grey7: palette.grey7,
    grey8: palette.grey8,
    greyMedium: palette.grey4,
    purple: palette.purple,
    purple2: palette.purple2,
    green: palette.green,
    green2: palette.green2,
    red: palette.red,
    red2: palette.red2,
    beigeMedium: palette.beigeMedium,
    beigeHighlight: palette.beigeHighlight,
    blue: palette.blue,
    blue2: palette.blue2,
    yellow: palette.yellow,
    yellow2: palette.yellow2,
    beige: palette.beige,
    lightBlack: palette.lightBlack,
    brightGray1: palette.brightGray1,
  },
  text: {
    white: palette.white,
    beige: palette.beige,
    black: palette.black,
    grey1: palette.grey1,
    grey2: palette.grey2,
    grey3: palette.grey3,
    grey4: palette.grey4,
    grey8: palette.grey8,
    purple: palette.purple,
    red: palette.red,
    red2: palette.red2,
    blue: palette.blue,
    blue2: palette.blue2,
    yellow: palette.yellow,
    beigeMedium: palette.beigeMedium,
    beigeHighlight: palette.beigeHighlight,
    green: palette.green,
  },
}

export default colors
