import { all, call, put, takeLeading } from 'redux-saga/effects'
import { addDays, addMinutes, endOfDay } from 'date-fns'

import api from '../../../../services/api'

import { actions, actionTypes } from './actions'

export function* load() {
  try {
    const getPageDataResponse = yield call(api.getCallbackFormPageData)
    yield put(actions.loadSuccess(getPageDataResponse.data.items[0]))
    const formFieldData = getPageDataResponse.data.items[0].formFields.find(
      ({ name }) => name === 'Appointment_Date_Time__c' || 'Appointment_Date__c'
    )

    if (
      getPageDataResponse &&
      formFieldData.name === 'Appointment_Date_Time__c'
    ) {
      const now = new Date()

      const getAppointmentSlotsData = yield call(api.getAppointmentCandidates, {
        startTime: addMinutes(now, 30).toISOString(),
        endTime: addDays(endOfDay(now), 14).toISOString(),
        territoryIds: formFieldData.territoryIds,
      })

      if (getAppointmentSlotsData) {
        yield put(
          actions.loadCandidatesSuccess(getAppointmentSlotsData.data.candidates)
        )
      }
    }
  } catch (error) {
    yield put(actions.loadFailure(error, undefined))
  }
}

export function* save(action) {
  try {
    const data = action.payload.formInput
    const postCallbackFormResponse = yield call(api.postCallbackForm, data)

    if (postCallbackFormResponse) {
      yield put(actions.saveSuccess(postCallbackFormResponse))
    }
  } catch (error) {
    yield put(actions.saveFailure(error))
  }
}

export default function* callbackFormModalRoot() {
  yield all([
    takeLeading(actionTypes.LOAD, load),
    takeLeading(actionTypes.SAVE, save),
  ])
}
