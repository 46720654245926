import { articlePublishedDate } from '../../../utils/dates'

import { actionTypes } from './actions'
import { ArticleAction, ArticleState } from './types'

export const initialState: ArticleState = {
  error: false,
  loading: false,
  pageData: undefined,
}

const transformData = (pageData) => {
  pageData.items[0].metaData.publishedDateAge = articlePublishedDate(
    pageData.items[0].metaData.publishedDate
  )

  return pageData
}

export default (
  state: ArticleState = initialState,
  action: ArticleAction
): ArticleState => {
  switch (action.type) {
    case actionTypes.PAGE_DATA.GET:
      return {
        ...state,
        loading: true,
      }

    case actionTypes.PAGE_DATA.SUCCESS:
      return {
        ...state,
        loading: false,
        pageData: transformData(action.payload.pageData),
      }

    case actionTypes.PAGE_DATA.FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      }

    default:
      return state
  }
}
