import App, { AppInitialProps } from 'next/app'
import { NextPageContext } from 'next'
import Head from 'next/head'
import { useRouter } from 'next/router'
import NProgress from 'nprogress'
import React, { useEffect } from 'react'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { END } from 'redux-saga'
import 'semantic-ui-css/components/reset.min.css'
import cacheData from 'memory-cache'
import { ThemeProvider } from 'styled-components'
import { setUTM } from '../utils/helpers'
import { actions } from '../components/common/templates/Layout/actions'
import { SagaStore, wrapper } from '../configureStore'
import { EnvConfig } from '../constants/environmentConfig'
import theme from '../styles/theme'
import { setAPIContext } from '../utils'
import { initGA } from '../utils/analytics'
import { matchPathToRedirectMap } from '../redirects'

import '../utils/datadog'

declare module 'next/dist/next-server/lib/utils' {
  export interface NextPageContext {
    store: SagaStore
  }
}

const PageLoadObserver = () => {
  const router = useRouter()

  useEffect(() => {
    router.events.on('routeChangeStart', () => {
      NProgress.start()
    })
    router.events.on('routeChangeComplete', () => {
      NProgress.done()
      window.scrollTo(0, 0)
    })
    router.events.on('routeChangeError', () => NProgress.done())
  }, [router])

  useEffect(() => {
    setUTM()
  }, [])

  setUTM()

  return null // component does not render anything
}

const applyRedirect = async (context: NextPageContext) => {
  const map = await matchPathToRedirectMap(context.req.url, cacheData)

  if (map && map.targetUrl && context.res) {
    const statusCode = map.permanent ? 301 : 302

    context.res.writeHead(statusCode, {
      Location: map.targetUrl,
    })
    context.res.end()
  }
}
class SPW extends App<AppInitialProps> {
  public static getInitialProps = wrapper.getInitialAppProps(
    (store) =>
      async ({ Component, ctx }) => {
        const isServer = !!ctx.req

        if (isServer) {
          setAPIContext(ctx.req.headers.host)
          await applyRedirect(ctx)
        }

        const pageProps = {
          // Call page-level getInitialProps
          ...(Component.getInitialProps
            ? await Component.getInitialProps({ ...ctx, store })
            : {}),
        }

        if (isServer) {
          const { header, headerSlim, footer } = store.getState().global

          if (!header.data || !headerSlim.data || !footer.data) {
            store.dispatch(actions.globalDataGet())
          }

          store.dispatch(END)
          await (store as SagaStore).sagaTask.toPromise()
        }

        return { pageProps }
      }
  )

  componentDidMount() {
    setAPIContext(document.location.href)
    initGA()
  }

  render() {
    const { Component, pageProps } = this.props

    return (
      <React.StrictMode>
        <GoogleReCaptchaProvider
          reCaptchaKey={EnvConfig.SPW_UI_GOOGLE_CAPTCHA_V3_SITE_KEY}
        >
          <ThemeProvider theme={theme}>
            <Head>
              <meta
                name="viewport"
                content="initial-scale=1.0, width=device-width"
              />
            </Head>
            <PageLoadObserver />
            <Component {...pageProps} />
          </ThemeProvider>
        </GoogleReCaptchaProvider>
      </React.StrictMode>
    )
  }
}

export default wrapper.withRedux(SPW)
