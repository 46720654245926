import { all, call, put, takeLeading } from 'redux-saga/effects'

import api from '../../../services/api'

import { actions, actionTypes } from './actions'
import { AdviserProfileAction } from './types'

export function* getAdviserProfileData(action) {
  try {
    const homeDataResponse = yield call(
      api.getAdviserProfilePageData,
      action.payload.slug
    )

    yield put(actions.pageDataSuccess(homeDataResponse.data))
  } catch (error) {
    yield put(actions.pageDataFailure(error))
  }
}

export default function* homeRoot() {
  yield all([
    takeLeading<AdviserProfileAction>(
      actionTypes.PAGE_DATA.GET,
      getAdviserProfileData
    ),
  ])
}
