import { all } from 'redux-saga/effects'

import callbackFormModalRoot from '../components/common/organisms/CallbackFormModal/sagas'
import articlesRoot from '../components/common/templates/Layout/sagas'
import aboutRoot from '../components/containers/About/sagas'
import adviserProfileRoot from '../components/containers/AdviserProfile/sagas'
import advisers from '../components/containers/Advisers/sagas'
import articleRoot from '../components/containers/Article/sagas'
import campaignRoot from '../components/containers/Campaign/sagas'
import customSlugRoot from '../components/containers/CustomSlug/sagas'
import contactRoot from '../components/containers/Contact/sagas'
import faqRoot from '../components/containers/Faq/sagas'
import fundInfoRoot from '../components/containers/FundInfo/sagas'
import homeRoot from '../components/containers/Home/sagas'
import howItWorksRoot from '../components/containers/HowItWorks/sagas'
import mediaCentreRoot from '../components/containers/MediaCentre/sagas'
import preferencesCenterRoot from '../components/containers/PreferenceCentre/sagas'
import pricingRoot from '../components/containers/Pricing/sagas'
import profilingToolRoot from '../components/containers/ProfilingTool/sagas'
import profilingToolResultsRoot from '../components/containers/ProfilingToolResults/sagas'
import referralRoot from '../components/containers/Referral/sagas'
import referRoot from '../components/containers/Refer/sagas'
import whatWeDoRoot from '../components/containers/WhatWeDo/sagas'
import workWithUsRoot from '../components/containers/WorkWithUs/sagas'

export default function* rootSaga() {
  yield all([
    aboutRoot(),
    adviserProfileRoot(),
    advisers(),
    articleRoot(),
    articlesRoot(),
    callbackFormModalRoot(),
    campaignRoot(),
    customSlugRoot(),
    referralRoot(),
    referRoot(),
    contactRoot(),
    faqRoot(),
    fundInfoRoot(),
    homeRoot(),
    howItWorksRoot(),
    mediaCentreRoot(),
    preferencesCenterRoot(),
    pricingRoot(),
    profilingToolResultsRoot(),
    profilingToolRoot(),
    whatWeDoRoot(),
    workWithUsRoot(),
  ])
}
