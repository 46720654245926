import { GLOBAL_REDUCER_NAMESPACE } from '../../../../constants'
import { namespacer } from '../../../../utils'

import { ArticleArguments, GlobalAction } from './types'

const namespacedAction = namespacer(GLOBAL_REDUCER_NAMESPACE)

export const actionTypes = {
  GLOBAL_DATA: {
    GET: namespacedAction('GLOBAL_DATA_GET'),
    SUCCESS: namespacedAction('GLOBAL_DATA_SUCCESS'),
    FAILURE: namespacedAction('GLOBAL_DATA_FAILURE'),
  },
  VIEWPORT_INFO: {
    SET: namespacedAction('VIEWPORT_INFO_SET'),
  },
  HEADER_STATUS: {
    SET: namespacedAction('HEADER_STATUS_SET'),
  },
  PAGE_DATA: {
    FAILURE: namespacedAction('PAGE_DATA_FAILURE'),
    GET: namespacedAction('PAGE_DATA_GET'),
    SUCCESS: namespacedAction('PAGE_DATA_SUCCESS'),
  },
  SELECTED_ARTICLES: {
    GET: namespacedAction('SELECTED_ARTICLES_GET'),
    FAILURE: namespacedAction('SELECTED_ARTICLES_FAILURE'),
    SUCCESS: namespacedAction('SELECTED_ARTICLES_SUCCESS'),
  },
}

export const actions = {
  globalDataGet(): GlobalAction {
    return {
      payload: {},
      type: actionTypes.GLOBAL_DATA.GET,
    }
  },
  globalDataSuccess(globalData): GlobalAction {
    return {
      payload: {
        globalData,
      },
      type: actionTypes.GLOBAL_DATA.SUCCESS,
    }
  },
  globalDataFailure(error): GlobalAction {
    return {
      payload: {
        error,
      },
      type: actionTypes.GLOBAL_DATA.FAILURE,
    }
  },

  viewportInfoSet(viewportInfo): GlobalAction {
    return {
      payload: {
        viewportInfo,
      },
      type: actionTypes.VIEWPORT_INFO.SET,
    }
  },

  headerStatusSet(status) {
    return {
      payload: {
        status,
      },
      type: actionTypes.HEADER_STATUS.SET,
    }
  },
  selectedArticlesGet(
    limit: number,
    offset: number,
    preview = false,
    args?: ArticleArguments
  ): GlobalAction {
    return {
      payload: {
        selectedArticles: {
          limit,
          offset,
          ...args,
        },
        preview,
      },
      type: actionTypes.SELECTED_ARTICLES.GET,
    }
  },
  selectedArticlesSuccess(selectedArticles): GlobalAction {
    return {
      payload: {
        selectedArticles: {
          data: selectedArticles,
        },
      },
      type: actionTypes.SELECTED_ARTICLES.SUCCESS,
    }
  },
  selectedArticlesFailure(error): GlobalAction {
    return {
      payload: {
        selectedArticles: { error },
      },
      type: actionTypes.SELECTED_ARTICLES.FAILURE,
    }
  },
}
