import { all, call, put, takeLeading } from 'redux-saga/effects'

import api from '../../../services/api'

import { actions, actionTypes } from './actions'
import { CustomSlugAction } from './types'

export function* getCustomSlugData(action) {
  try {
    const customSlugDataResponse = yield call(
      api.getCustomSlugPageData,
      action.payload.parentSlug,
      action.payload.slug
    )

    yield put(actions.pageDataSuccess(customSlugDataResponse.data))
  } catch (error) {
    yield put(actions.pageDataFailure(error))
  }
}

export default function* customSlugRoot() {
  yield all([
    takeLeading<CustomSlugAction>(actionTypes.PAGE_DATA.GET, getCustomSlugData),
  ])
}
