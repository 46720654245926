import { all, call, put, takeLeading } from 'redux-saga/effects'

import api from '../../../services/api'

import { actions, actionTypes } from './actions'
import { MediaCentreAction } from './types'

export function* getMediaCentreData() {
  try {
    const mediaCentreDataResponse = yield call(api.getMediaCentrePageData)

    yield put(actions.pageDataSuccess(mediaCentreDataResponse.data))
  } catch (error) {
    yield put(actions.pageDataFailure(error))
  }
}

export default function* mediaCentreRoot() {
  yield all([
    takeLeading<MediaCentreAction>(
      actionTypes.PAGE_DATA.GET,
      getMediaCentreData
    ),
  ])
}
