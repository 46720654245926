import { all, call, put, takeLeading } from 'redux-saga/effects'

import api from '../../../services/api'

import { actions, actionTypes } from './actions'
import { PricingAction } from './types'

export function* getPricingData() {
  try {
    const pricingDataResponse = yield call(api.getPricingPageData)

    yield put(actions.pageDataSuccess(pricingDataResponse.data))
  } catch (error) {
    yield put(actions.pageDataFailure(error))
  }
}

export default function* pricingRoot() {
  yield all([
    takeLeading<PricingAction>(actionTypes.PAGE_DATA.GET, getPricingData),
  ])
}
