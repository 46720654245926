import { ColorTheme } from '../../../types/colorThemes'
import colors from '../colors'
import { callToActionTertiary } from './common'

const blue: ColorTheme = {
  logo: {
    iconThemeColor: colors.ui.white,
  },
  header: {
    menuButtonBaseColor: colors.text.white,
    linkBaseColor: colors.text.white,
    utilityLinkHoverColor: colors.text.blue,
    utilityIconColor: 'currentColor',
  },
  hero: {
    withText: {
      textBaseColor: colors.text.black,
      textOnOverlayBaseColor: colors.text.white,
      textThemeColor: colors.text.white,
      textAccentBaseColor: colors.text.blue,
      textAccentThemeColor: colors.text.white,
    },
    withImage: {
      textAccentThemeColor: colors.text.blue,
    },
  },
  backgroundImage: {
    overlayBaseColor: colors.background.black,
    overlayThemeColor: colors.background.blue,
  },
  richText: {
    textAccentThemeColor: colors.text.blue,
  },
  threeColumnsIcons: {
    textAccentThemeColor: colors.text.blue,
  },
  callbackBanner: {
    textAccentThemeColor: colors.text.blue,
  },
  featureBlock: {
    textThemeColor: colors.text.black,
    textAccentThemeColor: colors.text.blue,
    backgroundThemeColor: colors.background.white,
    listItemAccentThemeColor: colors.background.blue,
    boxed: {
      backgroundThemeColor: colors.background.black,
      contentBackgroundThemeColor: colors.background.white,
      textThemeColor: colors.text.black,
      textAccentThemeColor: colors.text.blue,
    },
  },
  callToAction: {
    primary: {
      active: {
        textThemeColor: colors.background.white,
        backgroundThemeColor: colors.background.blue,
        borderThemeColor: colors.background.blue,
        backgroundHoverThemeColor: colors.background.blue2,
      },
      disabled: {
        textThemeColor: colors.background.black,
        backgroundThemeColor: colors.background.grey2,
      },
    },
    secondary: {
      active: {
        textThemeColor: colors.text.blue,
        backgroundThemeColor: colors.background.grey1,
        backgroundHoverThemeColor: colors.background.white,
        borderThemeColor: colors.ui.blue,
      },
      disabled: {
        textThemeColor: colors.background.grey2,
        backgroundThemeColor: colors.background.white,
        borderThemeColor: colors.ui.grey2,
      },
    },
    tertiary: callToActionTertiary,
  },
  link: {
    textAccentThemeColor: colors.text.blue,
    textUnderlineThemeColor: colors.ui.blue,
  },
  input: {
    labelThemeColor: colors.text.blue,
    borderThemeColor: colors.ui.blue,
    backgroundThemeColor: colors.background.blue,
    boxShadowThemeColor: colors.ui.blue,
  },
  scroll: {
    iconThemeColor: colors.ui.white,
    backgroundThemeColor: colors.background.blue,
    backgroundHoverThemeColor: colors.background.blue2,
  },
  newsletterSignUpBanner: {
    textAccentThemeColor: colors.text.blue,
  },
  tabbedContentBlock: {
    textAccentThemeColor: colors.text.blue,
  },
  tabs: {
    backgroundThemeColor: colors.background.white,
    textThemeColor: colors.text.black,
    borderThemeColor: colors.background.beige,
    selectedBorderThemeColor: colors.ui.blue,
    selectedTextThemeColor: colors.text.blue,
    selectedIconThemeColor: colors.ui.white,
    selectedIconBackgroundThemeColor: colors.background.blue,
  },
  anchorNav: {
    backgroundThemeColor: colors.background.white,
    selectedBackgroundThemeColor: colors.background.greyLight,
    textThemeColor: colors.text.black,
    textTitleThemeColor: colors.text.grey4,
    textAccentThemeColor: colors.text.blue,
  },
  selectedArticles: {
    textAccentThemeColor: colors.text.blue,
  },
  pricing: {
    textAccentThemeColor: colors.text.blue,
  },
  pricingDetailBlock: {
    borderThemeColor: colors.ui.blue,
  },
  priceBlockCalculator: {
    textAccentThemeColor: colors.text.blue,
    callOrBeCalledBackgroundThemeColor: 'transparent',
  },
  priceInput: {
    borderThemeColor: colors.ui.blue,
    boxShadowThemeColor: colors.ui.blue,
  },
  slider: {
    trackThemeColor: colors.ui.blue,
    thumbBackgroundThemeColor: colors.ui.blue,
  },
  knowledgeCentre: {
    textAccentThemeColor: colors.text.blue,
  },
  adviserLocator: {
    textAccentThemeColor: colors.text.blue,
  },
  contactCard: {
    textHoverThemeColor: colors.text.blue,
    iconHoverThemeColor: colors.text.blue,
  },
  articleCard: {
    overlayThemeColor: colors.background.blue,
    textThemeColor: colors.text.white,
  },
  articleHero: {
    textBaseColor: colors.text.white,
    iconBaseColor: colors.ui.white,
  },
  twoColumnContentBlock: {
    textThemeColor: colors.text.white,
    backgroundThemeColor: colors.background.blue,
    listItemThemeColor: colors.text.white,
  },
  callOutBanner: {
    backgroundThemeColor: colors.background.black,
  },
  contentBlock: {
    textBaseColor: colors.text.black,
    textNestedBaseColor: colors.text.white,
    textAccentThemeColor: colors.text.blue,
  },
  accordion: {
    textThemeColor: colors.text.white,
    textAccentBaseColor: colors.text.blue,
    textAccentThemeColor: colors.text.blue,
    borderThemeColor: colors.text.blue,
  },
  pullQuote: {
    borderThemeColor: colors.ui.blue,
  },
  keyFacts: {
    textThemeColor: colors.text.white,
    textAccentThemeColor: colors.text.blue,
    backgroundHoverThemeColor: colors.background.blue,
    buttonBackgroundThemeColor: colors.background.blue2,
    iconThemeColor: colors.ui.blue,
  },
  orderedList: {
    textThemeColor: colors.text.white,
    borderThemeColor: colors.text.white,
  },
  textHighlight: {
    backgroundThemeColor: colors.background.beigeHighlight,
    textThemeColor: colors.text.black,
    textAccentThemeColor: colors.text.blue,
    iconThemeColor: colors.background.black,
  },
  profilingToolResults: {
    textAccentThemeColor: colors.text.blue,
  },
}

export default blue
