import { GenerateRequestUrl } from './types'

/**
 * A function that creates a API request URL from the provided arguments.
 *
 * @param contentType - Required. The content-type being requested.
 * @param slug - Optional. The corresponding slug of a specific content-type
 * item.
 *
 * @returns string - The formed API request URL.
 */
export const generateRequestUrl: GenerateRequestUrl = (
  contentType,
  slug,
  parentSlug
) => {
  const path = `/content/?type=${contentType}`

  if (slug && parentSlug) {
    return `${path}&parentSlug=${parentSlug}&slug=${slug}`
  }

  if (slug) {
    return `${path}&slug=${slug}`
  }

  return path
}
