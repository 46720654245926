import { actionTypes } from './actions'
import { ProfilingToolAction, ProfilingToolState } from './types'

export const initialState: ProfilingToolState = {
  error: false,
  loading: false,
  pageData: undefined,
  questionSteps: undefined,
  loadingScreenText: undefined,
}

export default (
  state: ProfilingToolState = initialState,
  action: ProfilingToolAction
): ProfilingToolState => {
  switch (action.type) {
    case actionTypes.PAGE_DATA.GET:
      return {
        ...state,
        loading: true,
      }

    case actionTypes.PAGE_DATA.SUCCESS:
      return {
        ...state,
        loading: false,
        pageData: action.payload.pageData,
        questionSteps: action.payload.pageData.items[0].questionSteps,
        loadingScreenText: action.payload.pageData.items[0].loadingScreenText,
        metaData: action.payload.pageData.items[0].metaData,
      }

    case actionTypes.PAGE_DATA.FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      }

    default:
      return state
  }
}
